// Dependencies
import React from "react";
// Hooks
import useSettings from "../hooks/useSettings";
import useNewsletterResult from "../hooks/useNewsletterResult";
// Components
import Layout from "../components/Layout";
import Header from "../components/Header";
import Markdown from "../components/Markdown";
import Footer from "../components/Footer";
import NewsletterForm from "../components/NewsletterForm";

const NewsletterPage = () => {

  // Content
  const { newsletterTitle, newsletterCopy, newsletterSuccess, newsletterTryAgain } = useSettings();

  // UX
  const [isSubmitted, isSuccess] = useNewsletterResult();

  return (
    <Layout title={"Newsletter"}>
      <Header />
      <div className="container">
        <section className="grid page-section has-dark-links">
          {isSubmitted ? (
            isSuccess ? (
              <Markdown className="col-4 p2 has-indents" field={newsletterSuccess} />
            ) : (
              <Markdown className="col-4 p2 has-indents" field={newsletterTryAgain} />
            )
          ) : (
            <div>
              <h2>{newsletterTitle}</h2>
              <p className="p2">{newsletterCopy.text}</p>
            </div>
          )}
          <div className="col-2">
            <NewsletterForm />
          </div>
        </section>
      </div>
      <Footer />
    </Layout>
  )
}

export default NewsletterPage
