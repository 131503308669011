// Dependencies
import React, { useState, useContext } from "react";
// Hooks
// ...
// Components
import Stripes from "../components/Stripes";

const Footer = ({ activeCategory }) => {
  return (
    <footer>
      <Stripes activeCategory={activeCategory} />
    </footer>
  )
}

export default Footer
