import { useState, useEffect } from "react";
import { useLocation } from "@reach/router";

export default function useNewsletterResult() {

  const location = useLocation();
  const [isSubmitted, setSubmitted] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [isInvalid, setInvalid] = useState(false);

  useEffect(() => {
    if (location.search) {
      const success = location.search.includes('success');
      const error = location.search.includes('error');
      const invalid = location.search.includes('invalidemail');

      setSubmitted(true)
      setSuccess(success)
      setError(error)
      setInvalid(invalid)
    }
  }, [location])

  // UI
  const reset = () => {
    setSubmitted(false)
    setSuccess(false)
    setError(false)
    setInvalid(false)
  }

  return [isSubmitted, isSuccess, isError, isInvalid, reset]
}
