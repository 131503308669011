// Dependencies
import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
// Hooks
import useSettings from "../hooks/useSettings";
import useNewsletterResult from "../hooks/useNewsletterResult";
// Components
import Markdown from "../components/Markdown";

const Input = (props) => {
  const [value, set] = useState(props.value || "");
  return (
    <div>
      <label htmlFor={props.name}>{props.label}</label>
      <input {...props} value={value} onChange={e => set(e.target.value)} />
    </div>
  )
}

const NewsletterForm = () => {

  // Content
  const { newsletterCopy, newsletterDisclaimer } = useSettings();
  const location = useLocation();

  // UX
  const [isSubmitted, isSuccess, isError, isInvalid, reset] = useNewsletterResult();

  return (
    <div className={`newsletter-sign-up`}>
      <div className="newsletter-form">
        <p className="p3">{newsletterCopy.text}</p>

        {isSubmitted && (
          <div className="outerx2">

            {isInvalid && !isSuccess && (
              <p className="p3 color-error">There was a problem with your e-mail, please try again.</p>
            )}

            {isSuccess && !isError ? (
              <p className="p3 color-success">Thank you for subscribing!</p>
            ) : isSuccess && isError && (
              <p className="p3 color-success">Thank you for subscribing, again!</p>
            )}
          </div>
        )}

        <div className="p3">
          <form action="https://r1.dmtrk.net/signup.ashx" method="post" autoComplete="off">
            <Input label="First Name" type="text" name="cd_FIRSTNAME" placeholder="First Name" />
            <Input label="Last Name" type="text" name="cd_LASTNAME" placeholder="Last Name" />
            <Input label="E-mail" type="email" name="email" placeholder="E-mail" />
            <input type="submit" name="btnsubmit" value="Submit" />
            {/* Hidden fields */}
            <input type="hidden" name="userid" value="184904" />
            <input type="hidden" name="SIG212ca41088756f1abbe6fa805225400cb999cc78850210abf4c541045630bef5" value="" />
            <input type="hidden" name="ReturnURL" value={`${location.origin}/newsletter`} />
            <input type="hidden" name="addressbookid" value="556010" />
            <input type="hidden" id="ci_consenturl" name="ci_consenturl" value="" />
          </form>
        </div>
        <Markdown className="outer align-center has-dark-links" field={newsletterDisclaimer} />
      </div>
    </div>

  )
}

export default NewsletterForm
